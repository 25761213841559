import React, { useState, useEffect, useRef } from "react"
import { toCamelCase } from "../../utils/utils"
import SeamlessIframe from "../../components/SeamlessEmbed"

const LocationForms = ({ activeForm }) => {
  if (!activeForm.newForm) return ""
  const [formUrl, setFormUrl] = useState("")

  //   const filename = `/${toCamelCase(activeForm.newForm)}Form.js`
  //   const scriptID = `${toCamelCase(activeForm.newForm)}-contact-script`
  //   const prevScriptID = activeForm.prevForm
  //     ? `${toCamelCase(activeForm.prevForm)}-contact-script`
  //     : ""

  useEffect(() => {
    if (activeForm.newForm.url) {
      setFormUrl(activeForm.newForm.url)

      const form = document.getElementById("contact-page-form")
      setTimeout(() => {
        window.scroll({ top: form.offsetTop - 88, left: 0, behavior: "smooth" })
      }, 600)
    }

    return () => {
      // const script = document.getElementById(scriptID);
      // if(script){
      //     script.remove();
      // }
    }
  }, [activeForm.newForm.url])

  //   const appendScript = () => {
  //     let script = document.createElement("script")
  //     script.src = filename
  //     script.async = true
  //     script.setAttribute("id", scriptID)
  //     document.body.appendChild(script)
  //   }

  //   const updateScript = () => {
  //     const script = document.getElementById(scriptID)

  //     if (script) {
  //       script.remove()
  //       appendScript()
  //       return
  //     } else {
  //       appendScript()
  //     }
  //   }

  return (
    <div className="columns contact-bottom location-contact-form">
      <div className="column is-4"></div>
      <div className="column">
        <center>
          <div style={{ maxWidth: "1000px" }}>
            <div id="contact-page-form">
              {activeForm.newForm.locationForm && (
                <h3>{`Message Our ${activeForm.newForm.locationForm} Office`}</h3>
              )}
              <p className="required-fields">
                <span className="required-asterisk">*</span>
                Required Fields
              </p>
            </div>
            <div
              style={{
                minHeight: activeForm.newForm.url ? "600px" : 0
              }}
              className="seamless-form-wrapper"
            >
              <SeamlessIframe src={formUrl} />
            </div>
          </div>
        </center>
      </div>

      <div className="column is-4"></div>
    </div>
  )
}

export default LocationForms
