import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Stars from "./Stars"
import Button from "@bit/azheng.joshua-tree.button"
import classnames from "classnames"

const AllSocialReviews = ({
  reviews,
  language,
  heading,
  buttonHref,
  buttonText,
  isColumn,
  colorBack,
  animation
}) => {
  const animate = animation ? "fade-up" : ""

  const wrapperClasses = classnames({
    "home-section": heading,
    "ratings-review top-down": true,
    "color-back": colorBack
  })

  const reviewsContainer = classnames({
    "all-reviews-container-flex": isColumn,
    "all-reviews-container-grid": !isColumn
  })

  const data = useStaticQuery(graphql`
    query SocialQuery {
      googleJson {
        data {
          code
          platform
          platformId
          totalReviews
          rating
          url
        }
      }
      facebookJson {
        data {
          code
          platform
          platformId
          totalReviews
          rating
          url
        }
      }
      fresnoJson {
        data {
          code
          platform
          platformId
          totalReviews
          rating
          url
        }
      }
      clovisJson {
        data {
          code
          platform
          platformId
          totalReviews
          rating
          url
        }
      }
      reedleyJson {
        data {
          code
          platform
          platformId
          totalReviews
          rating
          url
        }
      }
      maderaJson {
        data {
          code
          platform
          platformId
          totalReviews
          rating
          url
        }
      }
      visaliaJson {
        data {
          code
          platform
          platformId
          totalReviews
          rating
          url
        }
      }
    }
  `)

  let l1 = data.fresnoJson.data
  let l2 = data.clovisJson.data
  let l3 = data.reedleyJson.data
  let l4 = data.maderaJson.data
  let l5 = data.visaliaJson.data

  l1.url =
    "https://www.google.com/search?q=fresno+oral+and+maxillofacial+surgery+1903+E+Fir+Ave%2C+Ste+101+Fresno%2C+CA+93720&rlz=1C5CHFA_enUS910US910&ei=rgt_YPmCNoK7tAbZ4bjAAg&oq=fresno+oral+and+maxillofacial+surgery+1903+E+Fir+Ave%2C+Ste+101+Fresno%2C+CA+93720&gs_lcp=Cgdnd3Mtd2l6EAM6BwgAEEcQsAM6BQgAEJECOggIABCxAxCDAToCCAA6CwguELEDEMcBEKMCOggILhDHARCjAjoLCC4QxwEQrwEQkQI6BAgAEEM6BAguEEM6DgguEMcBEK8BEJECEJMCOgUILhCxAzoFCAAQsQM6CgguEMcBEK8BEEM6AgguOgcIABDJAxBDOgUIABDJAzoICC4QxwEQrwE6CAghEBYQHRAeOgYIABAWEB5Q5rUEWPTbBGDo3QRoA3ACeACAAd4DiAGKHpIBCjUuMTAuMS4zLjKYAQCgAQGgAQKqAQdnd3Mtd2l6yAEIwAEB&sclient=gws-wiz&ved=0ahUKEwj5kZWAqY3wAhWCHc0KHdkwDigQ4dUDCA8&uact=5#lrd=0x80945d912d90b6a1:0xe1699abf1340c213,1"
  l2.url =
    "https://www.google.com/search?q=fresno+oral+and+maxillofacial+surgery+2026+Shaw+Ave%2C+Ste+101+Clovis%2C+CA+93611&rlz=1C5CHFA_enUS910US910&ei=_Qt_YJjKNILatQb8u7iYBw&oq=fresno+oral+and+maxillofacial+surgery+2026+Shaw+Ave%2C+Ste+101+Clovis%2C+CA+93611&gs_lcp=Cgdnd3Mtd2l6EANQhMwDWITMA2C7zgNoAHACeACAAV-IAbEBkgEBMpgBAKABAqABAaoBB2d3cy13aXrAAQE&sclient=gws-wiz&ved=0ahUKEwjYvOmlqY3wAhUCbc0KHfwdDnMQ4dUDCA8&uact=5#lrd=0x80945b94f00f30e9:0xafa9c4cfa00784cf,1"
  l3.url =
    "https://www.google.com/search?q=fresno+oral+and+maxillofacial+surgery+1140+F+St+Reedley%2C+CA+93654&rlz=1C5CHFA_enUS910US910&ei=Ogx_YPryHvPstQbf-7KQCw&oq=fresno+oral+and+maxillofacial+surgery+1140+F+St+Reedley%2C+CA+93654&gs_lcp=Cgdnd3Mtd2l6EANQw4ABWMOAAWC5ggFoAHACeACAAWmIAbABkgEDMS4xmAEAoAECoAEBqgEHZ3dzLXdpesABAQ&sclient=gws-wiz&ved=0ahUKEwj6997CqY3wAhVzds0KHd-9DLIQ4dUDCA8&uact=5#lrd=0x8094e2c38fab733d:0x1c666fd0906f73c7,1"
  l4.url =
    "https://www.google.com/search?q=fresno+oral+and+maxillofacial+surgery+515+N+I+St+Madera%2C+CA+93637&rlz=1C5CHFA_enUS910US910&ei=TAx_YPzPLpextAb_n4aoAQ&oq=fresno+oral+and+maxillofacial+surgery+515+N+I+St+Madera%2C+CA+93637&gs_lcp=Cgdnd3Mtd2l6EAM6BwgAEEcQsANQwnlYwnlgl35oAXACeACAAWqIAbYBkgEDMS4xmAEAoAECoAEBqgEHZ3dzLXdpesgBCMABAQ&sclient=gws-wiz&ved=0ahUKEwj8pbnLqY3wAhWXGM0KHf-PARUQ4dUDCA8&uact=5#lrd=0x80940b880c9e41c5:0xe5ff6cc7761d914b,1"

  let facebook = data.facebookJson.data

  if (!reviews) {
    reviews = [
      { name: "Fresno", ...l1 },
      { name: "Clovis", ...l2 },
      { name: "Reedley", ...l3 },
      { name: "Madera", ...l4 },
      { name: "Visalia", ...l5 },
      { name: "", ...facebook }
    ]
  }

  return (
    <section className={wrapperClasses}>
      <div className="container" data-aos={animate} data-aos-duration="1200">
        {heading && (
          <div className="columns">
            <div className="column is-2"></div>
            <div className="column is-20">
              <h2 className="has-text-centered h3-style">{heading}</h2>
            </div>
            <div className="column is-2"></div>
          </div>
        )}
        <div
          className="rating-reviews home-reviews columns is-centered iconless big-rating reviews-width"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          {/* <div className="column is-2"></div> */}
          <div
            className={reviewsContainer}
            style={{ flexDirection: isColumn ? "column" : "row" }}
          >
            {reviews &&
              reviews.map((review, i) => (
                <div className="featured-social">
                  <div>
                    {!review.name && isColumn ? (
                      <></>
                    ) : (
                      <span
                        role="presentation"
                        className="reviews-city"
                        style={{
                          fontSize: "0.875rem",
                          lineHeight: "1.71",
                          color: "#6d6e6f",
                          display: "block",
                          marginBottom: review.name ? "-4px" : "20px",
                          fontWeight: "bold",
                          fontFamily: "Montserrat"
                        }}
                      >
                        {!review.name
                          ? ""
                          : review.name && language === "es"
                          ? "Oficina de " + review.name
                          : review.name + " Office"}
                        {/* { review.name ? review.name + " Office" : ""} */}
                      </span>
                    )}
                    <a
                      aria-label="open link in new browser tab"
                      title="View all of our reviews"
                      className="link-wrapper"
                      href={review.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h3>
                        {review.platform === "googleMyBusiness"
                          ? "Google"
                          : "Facebook"}
                      </h3>
                      <div className="total-reviews">
                        ({review.totalReviews})
                      </div>
                    </a>
                  </div>
                  <div>
                    <a
                      aria-label="open link in new browser tab"
                      title="View all of our reviews"
                      href={review.url}
                      className="reviews-link"
                      target="_blank"
                    >
                      <div className="review-icons">
                        <Stars rating={review.rating} />
                      </div>
                    </a>
                    <a
                      aria-label="open link in new browser tab"
                      title="View all of our reviews"
                      href={review.url}
                      className="reviews-link"
                      target="_blank"
                    >
                      <span className="reviews-number">
                        {review.rating === 5 ? "5.0" : review.rating}
                      </span>
                    </a>
                  </div>
                </div>
              ))}
          </div>
          {/* <div className="column is-2"></div> */}
        </div>
      </div>

      {buttonHref && (
        <div className="columns has-text-centered">
          <div className="column">
            <Button contained buttonText={buttonText} href={buttonHref} />
          </div>
        </div>
      )}
    </section>
  )
}

export default AllSocialReviews
